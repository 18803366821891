import { useEffect } from 'react';
import GoogleTagManager from 'react-gtm-module';

const withTracking = (Component) => (properties) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.id = 'hs-script-loader';
        script.src = '//js-eu1.hs-scripts.com/26245961.js';
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    GoogleTagManager.initialize({ gtmId: 'GTM-W9F7X5JR' });

    return <Component {...properties} />;
};

export default withTracking;
